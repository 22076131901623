import React, {useState, useRef, useCallback} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {useEffect, useContext} from 'react';
import SignatureCanvas from 'react-signature-canvas';
import 'bootstrap/dist/css/bootstrap.min.css';
import './theme.css';
import {GeneralContext} from '../../context/GeneralContext';
import {DataContext} from "../../context/DataContext";
import {numberWithCommas, statusBadgeClasses, titleCase} from "../../utils";

const ConsignmentForm = ({getTransactionsByKey, stream, action}) => {

    const { state } = useLocation();
    const {axios} = useContext(GeneralContext);
    const {user} = useContext(DataContext);
    const navigate = useNavigate();

    const [formState, setFormState] = useState({
        stage: '',
        journeyId: '',
        trailerId: '',
        driverId: '',
        items: []
    });

    const [status, setStatus] = useState("");

    const [hasSignature, setHasSignature] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const sigCanvas = useRef(null);
    const [imageURL, setImageURL] = useState(null);
    const [supportingNotes, setSupportingNotes] = useState("");
    const [signerName, setSignerName] = useState("");
    const [showRejectError, setShowRejectError] = useState("");
    const [showSubmitError, setShowSubmitError] = useState("");
    const [journeyId, setJourneyId] = useState(state ? state.id : null);

    const drawComplete = () => {
        const url = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        setImageURL(url);
        setHasSignature(signerName.length > 0 && !sigCanvas.current.isEmpty());
    };

    const handleItemCheckChange = useCallback((index) => {
        let newItems = [...formState.items];
        newItems[index].checked = !newItems[index].checked;
        setFormState(prevState => ({...prevState, items: newItems}));
    }, [formState.items]);

    const handleNameChange = useCallback((value) => {
        setSignerName(value)
        setHasSignature(value.length > 0 && !sigCanvas.current.isEmpty());
    })

    const clearSignatureCanvas = useCallback(() => {
        sigCanvas.current.clear();
        setHasSignature(false);
    }, []);

    const validateForm = useCallback((action = null) => {
        if (!hasSignature || sigCanvas.current.isEmpty()) {
            return false;
        }

        return true;
    }, [hasSignature, supportingNotes]);

    const handleSubmit = async (event, actionType) => {
        event.preventDefault();
        setShowRejectError(false);
        setShowSubmitError(false);

        if (actionType === "reject" && supportingNotes === "") {
            setShowRejectError(true);
            return false;
        }
        const uncheckedItems = formState.items.filter((i) => i.checked === false).length;
        if(actionType === "submit" && uncheckedItems && supportingNotes === "") {
            setShowSubmitError(true);
            return false;

        }

        const formData = {
            id: formState.journeyId,
            createNFT: false,
            keys: [`journey-${formState.journeyId}`],
            role: null,
            payload: {
                ...formState,
                name: `Consignment ${action === "start" ? "Loading" : "Unloading"} form Signed & Submitted`,
                description: `A Consignment ${action === "start" ? "Loading" : "Unloading"} form has been Signed uploaded by the delivery driver.`,
                "Signature Name": signerName,
                notes: supportingNotes,
                action: actionType,
                signature: imageURL,
                stage: action,
            }
        };
        const {error, data: {data}} = await axios.post(
            `/event`, {
                data: formData
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

        if (!error) {
            console.log("Submit successful");
            setIsSubmitted(true);
            setTimeout(() => {
                navigate(`/journey-data`, {
                    state: {
                        journeyId: formState.journeyId
                    }
                })
            }, 5000);
        } else {
            alert(JSON.stringify(error));
            console.error(error);
        }
    };

    useEffect(async () => {

        if (action === "start") {
            document.title = "Consignment Loading Form";
            try {
                const {data} = await axios.get("/new-journey");
                setFormState({
                    journeyId: data.id,
                    consignmentId: data.consignment.reference,
                    trailerId: data.consignment.containers[0].id,
                    driverId: data.consignment.hauliers[0].drivers[0].id,
                    origin: data.route.origin,
                    destination: data.route.destination,
                    items: data.consignment.items.map(item => {
                        return {
                            ...item,
                            checked: false
                        }
                    })
                });

                const statusEvents = await axios.get(`/event/transactions-by-keys/?keys=journey-${data.id},status`);
                setStatus(statusEvents.data.data[0].data.json.metadata.status)
            } catch (e) {
                console.log(e);
                let sample1 = {
                    journeyId: '72e0077b-c522-4bbe-aede-0a0ce72e15c2',
                    consignmentId: 'MCF1000124',
                    trailerId: 'a88ac866-7cc3-4169-9f19-380a7cf13293',
                    driverId: '54efd4f5-aba4-4eef-b69d-9351b930bf0a',
                    origin: "McColgans Quality Foods Ltd",
                    destination: "Booker Distribution Centre",
                    items: [
                        {
                            "itemCode": "OTG002",
                            "description": "Snax on the Go 150g Chick and Mush Slice x6",
                            "packSize": "CASE",
                            "quantitySupplied": 160.00,
                            "useByDate": "23/06/2024",
                            "batchNumber": "157076",
                            "checked": false
                        },
                        {
                            "itemCode": "OTG003",
                            "description": "Snax on the Go 150g Beef and Vegetable Pasty x6",
                            "packSize": "CASE",
                            "quantitySupplied": 29.00,
                            "useByDate": "21/06/2024",
                            "batchNumber": "155846",
                            "checked": false
                        },
                        {
                            "itemCode": "OTG003",
                            "description": "Snax on the Go 150g Beef and Vegetable Pasty x6",
                            "packSize": "CASE",
                            "quantitySupplied": 51.00,
                            "useByDate": "23/06/2024",
                            "batchNumber": "157079",
                            "checked": false
                        },
                    ]
                };
                setFormState(sample1);
            }
        } else {
            document.title = "Consignment Unloading Form";
            try{
                const journeyTransactions = await axios.get(`/journey/transactions-by-key/journey-${journeyId}`);
                const consignmentTransactions = await axios.get(`/consignment/transactions-by-key/journey-${journeyId}`);
                const routeTransactions = await axios.get(`/route/transactions-by-key/route-${journeyId}`);
                const statusTransactions = await axios.get(`/event/transactions-by-keys/?keys=journey-${journeyId},status`);
                const consignmentData = consignmentTransactions.data.data[0].data.json;
                const journeyData = journeyTransactions.data.data[0].data.json;
                const routeData = routeTransactions.data.data[0].data.json;
                const latestStatusEvent = statusTransactions.data.data[statusTransactions.data.data.length - 1].data.json;
                setStatus(latestStatusEvent.metadata.status);

                setFormState({
                    journeyId: journeyData.id,
                    consignmentId: consignmentData.reference,
                    trailerId: consignmentData.containers[0].id,
                    driverId: consignmentData.hauliers[0].drivers[0].id,
                    origin: routeData.origin,
                    destination: routeData.destination,
                    items: consignmentData.items.map(item => {
                        return {
                            ...item,
                            checked: false
                        }
                    })
                });
            }
            catch (e) {
                console.log(e);
            }
        }


    }, []);

    return isSubmitted ? (
        <div className="mt-4 alert alert-success d-flex align-items-center">
            <div className="alert-icon">
                <i className="lni lni-play"></i>
            </div>
            <div className="alert-message">
                <h5 className="message-title">Success</h5>
                <p className="text">
                    Consignment form submitted successfully, redirecting to journey details within 5 seconds...
                </p>
            </div>
        </div>) : (
        <form onSubmit={(e) => e.preventDefault()}>
            <div className='two-column-container flex-grow-1'>
                <div className='row ml-0 mr-0'>

                    <div id='col-left' className='col-sm-12 col-md-8 flex-grow-1'>
                        <h2>{action === "start" ? "Consignment Loading Form" : "Consignment Unloading Form"}</h2>

                        <h3>Items List:</h3>
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Pos</th>
                                <th>HAWB No / Nature of Goods</th>
                                <th>Origin</th>
                                <th>Dest</th>
                                <th>Pieces</th>
                                <th>SLAC</th>
                                <th>Weight KG</th>
                                <th>Remarks</th>
                                <th>Checked</th>
                            </tr>
                            </thead>
                            <tbody>
                            {formState.items.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <input type="hidden" className="form-control" value={item.Pos} readOnly/>
                                        {item.Pos}
                                    </td>
                                    <td>
                                        <input type="hidden" className="form-control" value={item["HAWB No / Nature of Goods"]}
                                               readOnly/>
                                        {item["HAWB No / Nature of Goods"]}
                                    </td>
                                    <td>
                                        <input type="hidden" className="form-control" value={item.Origin}
                                               readOnly/>
                                        {item.Origin}
                                    </td>
                                    <td>
                                        <input type="hidden" className="form-control" value={item.Dest}
                                               readOnly/>
                                        {item.Dest}
                                    </td>
                                    <td>
                                        <input type="hidden" className="form-control" value={item.Pieces}
                                               readOnly/>
                                        {item.Pieces}
                                    </td>
                                    <td>
                                        <input type="hidden" className="form-control" value={item.SLAC}
                                               readOnly/>
                                        {item.SLAC}
                                    </td>
                                    <td>
                                        <input type="hidden" className="form-control" value={item["Weight KG"]}
                                               readOnly/>
                                        {numberWithCommas(item["Weight KG"].toFixed(2))}
                                    </td>
                                    <td>
                                        <input type="hidden" className="form-control" value={item["Remarks"]}
                                               readOnly/>
                                        {item["Remarks"]}
                                    </td>
                                    <td className="align-middle text-center">
                                        <input className='' type="checkbox" checked={item.checked}
                                               onChange={() => handleItemCheckChange(index)}/>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                    <div id='col-right' className='col-sm-12 col-md-4 h-100'>

                        <div className={'p-2'}>
                            <h3 className="pb-2">Journey Information</h3>
                            <ul>
                                <li><strong>Consignment ID:</strong> <span
                                    className="text-red font-bold text-2xl">{formState.consignmentId}</span></li>
                                <li><strong>Stage:</strong> <span
                                    className={`badge ${statusBadgeClasses[status]}`}>{titleCase(status)}</span></li>
                                <li><strong>Journey ID:</strong> {formState.journeyId}</li>
                                <li><strong>Trailer ID:</strong> {formState.trailerId}</li>
                                <li><strong>Driver ID:</strong> {formState.driverId}</li>
                                <li><strong>Origin</strong> {formState.origin}</li>
                                <li><strong>Destination</strong> {formState.destination}</li>
                            </ul>
                            <div className='border border-dashed p-2'>

                                <div className='mt-2 mb-2'>
                                    <h5 htmlFor="signer"><strong>Name</strong></h5>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={signerName}
                                        onChange={(e) => handleNameChange(e.target.value)}
                                        placeholder="Enter your name..."
                                    />
                                </div>
                                <h5>Signature</h5>
                                <div className='signature-container'>

                                    <SignatureCanvas penColor='black'
                                                     width={500}
                                                     height={200}
                                                     canvasProps={{
                                                         className: 'signature-canvas'
                                                     }}
                                                     ref={sigCanvas}
                                                     onEnd={drawComplete}
                                    />
                                </div>
                                <div className='mt-2 d-flex'>
                                    <button onClick={clearSignatureCanvas}
                                            className={"btn btn-primary btn-sm ml-auto"}>Clear Signature
                                    </button>
                                </div>
                                <div className='mt-2'>
                                    <label htmlFor="reason"><strong>Notes</strong></label>
                                    <textarea
                                        className="form-control"
                                        value={supportingNotes}
                                        onChange={(e) => setSupportingNotes(e.target.value)}
                                        placeholder="Add any supporting notes about this consignment..."
                                    />
                                </div>
                            </div>
                            <hr/>
                            <div className='row mt-3'>
                                {showSubmitError ?
                                    <span
                                        className='text-red mb-2'>Not all items have been checked, please provide supporting notes.</span> : ''}
                                {showRejectError ?
                                    <span
                                        className='text-red mb-2'>Please provide supporting notes for your rejection.</span> : ''}
                                {!hasSignature ?
                                    <span
                                        className='text-red mb-2'>Please provide your name, signature above and click "Save"</span> : ''}
                                <div className='col-12 d-flex justify-between'>
                                    <button type="submit" onClick={(e) => handleSubmit(e, 'reject')}
                                            disabled={!validateForm('reject')} className="btn btn-danger mt-3">Reject
                                    </button>
                                    <button type="submit" onClick={(e) => handleSubmit(e, 'submit')}
                                            disabled={!validateForm()} className='btn btn-success'>Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </form>
    );
}

export default ConsignmentForm;