import ReactJsonViewCompare from 'react-json-view-compare';
import PDFIcon from "./components/Shared/icons/pdf-solid";
import ImageIcon from "./components/Shared/icons/image-solid";
import DocIcon from "./components/Shared/icons/doc-solid";
import XMLIcon from "./components/Shared/icons/xml-solid";

const formatAreaCollection = (data) => {
    return data.map(marker => {
        const { id, area } = marker;
        return {
            "type": "Feature",
            "properties": {
                "name": id,
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [[area]]
            }
        }
    })
}

const formatAreaCollectionFromGeofences = (data) => {
    return data.map(marker => {
        const { name, coordinates } = marker;
        return {
            "type": "Feature",
            "properties": {
                "name": name,
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [coordinates]
            }
        }
    })
}

const handleFileRender = ({ extension, data, filename }) => {
    switch (true) {
        case ['pdf'].includes(extension):
            return <embed width="100%" className='h-100' src={data} />
        case ['xlsx', 'xls', 'csv'].includes(extension):
            return <div><span className={'text-danger'}>Unable to render file in browser, please download it below to view it.</span> <br/>
                <a
                className="btn btn-primary mt-10"
                download={filename}
                href={`${data}`}
            >{`Download ${filename}`}</a></div>;
        case ['json'].includes(extension):
            return <ReactJsonViewCompare oldData={data} newData={data} />
        case ['png', 'jpeg', 'jpg', 'gif', 'webp'].includes(extension):
            return <img alt="Render Images" className="m-auto" src={data} />
        default:
            return <></>
    }
}

const handleIcons = (type) => {
    switch (type) {
        case 'pdf':
            return PDFIcon;
        case 'jpg':
        case 'jpeg':
        case 'png':
            return ImageIcon;
        case 'doc':
            return DocIcon;
        case 'xml':
        case 'xls':
        case 'xlsx':
            return XMLIcon;
        default:
            return DocIcon;

    }
}

function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const defaultEventList = [
    {
        name: "Owner Validated",
        key: "Owner Validated",
        type: "user",
        icon: ""
    },
    {
        name: "Land Registered",
        key: "Land Registered",
        type: "field",
        icon: ""
    },
    {
        name: "Field Satellite Photo Taken",
        key: "Field Satellite Photo Taken",
        type: "field",
        icon: ""
    },
    {
        name: "Well Registered",
        key: "Well Registered",
        type: "well",
        icon: ""
    },
    {
        name: "Satellite Photo Taken",
        key: "Satellite Photo Taken",
        type: "well",
        icon: ""
    },
    {
        name: "Well Surveyed",
        key: "Well Surveyed",
        type: "well",
        icon: ""
    },

    {
        name: "Well Tested",
        key: "Well Tested",
        type: "well",
        icon: ""
    },
    {
        name: "Sample Taken",
        key: "Sample Taken",
        type: "well",
        icon: ""
    },
    {
        name: "Lab Result Received",
        key: "Lab Result Received",
        type: "well",
        icon: ""
    },
    {
        name: "Well Inspected",
        key: "Well Inspected",
        type: "well",
        icon: ""
    },
    {
        name: "Well Sealed",
        key: "Well Sealed",
        type: "well",
        icon: ""
    },
    {
        name: "Satellite Photo Taken",
        key: "Satellite Photo Taken 1",
        type: "well",
        icon: ""
    },
    {
        name: "Tokens Issued",
        key: "Tokens Issued",
        type: "well",
        icon: ""
    },
    {
        name: "Satellite Photo Taken",
        key: "Satellite Photo Taken 2",
        type: "well",
        icon: ""
    }

];

const titleCase = (str) => {
    // Function to check if a string is an acronym
    const isAcronym = (word) => word === word.toUpperCase() && word.length > 1;

    // Split the string by spaces, underscores, and transition from lowercase to uppercase
    let words = str.split(/(?<=[a-z])(?=[A-Z])|\s|_/).filter(word => word.length > 0);

    return words.map(word => {
        // If the word is an acronym, leave it as is
        if (isAcronym(word)) return word;

        // Special case for 'ID' (case-insensitive)
        if (word.toLowerCase() === 'id') return 'ID';

        // Split the word into segments, keeping numbers together
        let segments = word.split(/(?<=[a-z])(?=[A-Z0-9])/);

        return segments.map(segment => {
            // If segment is an acronym, leave it as is
            if (isAcronym(segment)) return segment;

            // Capitalize the first letter, lowercase the rest
            return segment.charAt(0).toUpperCase() + segment.slice(1).toLowerCase();
        }).join('');
    }).join(' ');
};

const statusBadgeClasses = {
    "": "badge-dark",
    "loading": "badge-warning",
    "started": "badge-primary",
    "in-transit": "badge-info",
    "rejected": "badge-danger",
    "completed": "badge-success"
}

const unpluralize = (word) => {
    const pluralSuffixes = ['s', 'es', 'ies']; // Common English plural suffixes

    for (const suffix of pluralSuffixes) {
        if (word.endsWith(suffix)) {
            return word.slice(0, -suffix.length); // Remove the plural suffix
        }
    }

    // If no plural suffix is found, return the original word
    return word;
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export {
    formatAreaCollection,
    handleFileRender,
    handleIcons,
    formatBytes,
    formatAreaCollectionFromGeofences,
    titleCase,
    defaultEventList,
    statusBadgeClasses,
    unpluralize,
    numberWithCommas
}